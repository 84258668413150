<template>
  <div>
    <b-modal
      ref="editModal"
      size="xl"
      @hide="slotFormOpen = false"
      :title="$t('calendarModule.modalTitles.editSlot')"
      hide-footer
    >
      <DefaultSlotForm
        v-if="slotFormOpen"
        :the-default-slot="theDefaultSlot"
        @slotsAddedOrSaved="slotsChanged"
        :withSlotActions="true"
      />
    </b-modal>

    <strong>{{ slotHeading }}</strong>
    <div>
      {{ $t("calendarModule.defaultSlots.processedUntil") }}:
      {{ processedUntilInfo }}
    </div>
    <div class="cm-card-actions">
      <b-icon-pencil
        v-if="withEdit"
        class="btn-link cm-action-icon"
        @click="showEditModal"
      />
    </div>
  </div>
</template>

<script>
import * as cmApi from "../../../store/modules/calendarModule/calendarModule.api";
import "vue-select/dist/vue-select.css";
import * as moment from "moment";

export default {
  name: "DefaultSlotInfo",
  props: ["theDefaultSlot", "withEdit", "withSelect"],
  components: {
    DefaultSlotForm: () =>
      import("../../../components/calendarModule/forms/DefaultSlotForm")
  },
  data() {
    return {
      cmApi,
      loading: false,
      loadingError: false,
      slotFormOpen: false
    };
  },
  computed: {
    slotHeading() {
      const name = this.slotTypeName(
        this.theDefaultSlot.metadataForSlot.slotType
      );
      let suffix = "";
      if (name) {
        suffix = ` ${name}`;
      }
      return `${moment(this.theDefaultSlot.from).format("dd")} (${moment(
        this.theDefaultSlot.from
      ).format("DD.MM.YYYY")} - ${moment(this.theDefaultSlot.to).format(
        "DD.MM.YYYY"
      )}) ${this.theDefaultSlot.time} - ${
        this.theDefaultSlot.endTime
      } ${suffix}`;
    },
    processedUntilInfo() {
      const name = this.slotTypeName(
        this.theDefaultSlot.metadataForSlot.slotType
      );
      let suffix = "";
      if (name) {
        suffix = ` ${name}`;
      }
      return `${moment(this.theDefaultSlot.processedUntil).format(
        "DD.MM.YYYY"
      )}`;
    }
  },
  methods: {
    showEditModal() {
      this.$refs["editModal"].show();
      this.slotFormOpen = true;
    },
    slotsChanged() {
      this.$emit("slotsChanged");
    },
    selectSlot() {
      this.$emit("selected", this.theDefaultSlot);
    },
    slotTypeName(slotType) {
      const languageType = slotType.split("_id_")[0];
      const name = "conversations";

      let result = this.$t("calendarModule.slots.typeNames." + languageType);
      if (name) {
        result = result.replace("{name}", name);
      }
      return result;
    }
  }
};
</script>
